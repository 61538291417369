var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BAlert', {
    staticClass: "px-2 py-1 font-weight-bolder",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Anh/Chị nghiêm túc không thực hiện giữ chỗ khống và huỷ các Booking nếu không có khách bay để tránh ảnh hưởng đến uy tín và toàn bộ mạng bán của " + _vm._s(_vm.mamaFullName) + ". Chúng tôi sẽ chuyển tiếp toàn bộ công văn xử phạt từ các Hãng (nếu có). ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }